import React, { FC, useEffect, useRef } from "react";
import { ContentWrap, Content } from "./styled.index";
import {textData} from "../../../data/text";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import gsap from "gsap";
const ContentLocation: FC = () => {
  useEffect(() => {
    ScrollTrigger.create({
      trigger: ContentWrapRef?.current || "",
      toggleActions: "play none none none",
      start: "top+=25% bottom",
      end: "center top",
      animation: gsap
        .timeline()
        .fromTo(
          ContentWrapRef?.current || null,
          .85,
          { opacity: 0, y: 40, ease: 'Power.easeInOut' },
          { opacity: 1, y: 0, ease: 'Power.easeInOut' },
          "+=0.5"
        ),
    });
  }, []);
  const ContentWrapRef = useRef(null);
  return (
    <ContentWrap ref={ContentWrapRef}>
      <Content>
        <div className={'col'}>
          {textData.location5.heading && <h3 className={"heading fs-xl"}>{textData.location5.heading}</h3>}
          <p className={"reg-text"}>{textData.location5.text}</p>
        </div>
        <div className={'col'}>
          {textData.location6.heading && <h3 className={"heading fs-xl"}>{textData.location6.heading}</h3>}
          <p className={"reg-text"}>{textData.location6.text}</p>
        </div>
      </Content>
    </ContentWrap>
  );
};
export default ContentLocation;
