import React, {FC, useEffect, useRef} from "react";
import {CompositionDiv, CompositionImage} from "./styled.index";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import gsap from "gsap";

interface IComposition {
  imgSrc?: string;
  altText?: string;
  height?: string;
  left?: string;
  right?: string;
  bottom?: string;
  top?: string;
  zIndex?: string;
  shape?: "circle" | "triangle";
  fade?: boolean;
  delay?: string;
  duration: number;
  fromVars: {};
  toVars: {};
}

const CompositionMap: FC<IComposition> = ({imgSrc, altText, height, left, right, bottom, top, shape, zIndex, delay, fade, duration, fromVars, toVars}) => {
  const CompositionImageRef = useRef<any>(null);
  useEffect(() => {
    ScrollTrigger.create({
      trigger: CompositionImageRef?.current.parentNode || "",
      toggleActions: "play none none none",
      start: "top center-=5%",
      end: "bottom top",
      animation: gsap
        .timeline()
        .fromTo(
          CompositionImageRef?.current || null,
          duration,
          fromVars,
          toVars,
          delay
        )
    });
  }, []);

  return (
    <CompositionImage
      ref={CompositionImageRef}
      top={top}
      left={left}
      right={right}
      bottom={bottom}
      height={height}
      src={imgSrc}
      alt={altText}
      zIndex={zIndex}
    />
  )
};

export default CompositionMap;
