import * as React from "react";
import { seoData } from "../data/seo";
import { navData } from "../data/nav";
import Seo from "../components/seo";
import ContentNextPage from "../components/content-next-page";
import Footer from "../components/footer";
import ContentLocation from "../components/location/content-location";
import Image from "../components/image";
import IntroVideo from "../components/intro-video";
import {introData} from "../data/intro";
import ContentText from "../components/content-text";
import {textData} from "../data/text";
import Composition from "../components/composition";
import ContentImageText from "../components/content-img-text";
import useWindowSize from "../helpers/UseWindowSize";
import NewComposition from "../components/composition/test";
import CompositionMap from "../components/composition/composition-map";
import {CompositionWrapper, MapWrapper} from "../components/composition/test/styled.index";
import leftPalms4 from "../assets/images/compos/marina_compos_4_left.png";
import rightPalms6 from "../assets/images/compos/marina_compos_6_right.png";
import leftLocation1 from "../assets/images/compos/location_compos_1_left.png";
import rightLocation1 from "../assets/images/compos/location_compos_1_right.png";

import mapBoat1 from "../assets/images/compos/map_compos_boat_1.png";
import mapBoat2 from "../assets/images/compos/map_compos_boat_2.png";
import mapBoat3 from "../assets/images/compos/map_compos_boat_3.png";
import mapLeft1 from "../assets/images/compos/map_compos_left_1.png";
import mapLeft2 from "../assets/images/compos/map_compos_left_2.png";
import mapLeft3 from "../assets/images/compos/map_compos_left_3.png";
import mapRight1 from "../assets/images/compos/map_compos_right_1.png";
import mapRight2 from "../assets/images/compos/map_compos_right_2.png";
import mapRight3 from "../assets/images/compos/map_compos_right_3.png";
import mapRight4 from "../assets/images/compos/map_compos_right_4.png";
import mapRight5 from "../assets/images/compos/map_compos_right_5.png";
import mapRight6 from "../assets/images/compos/map_compos_right_6.png";
import locationMap from "../assets/images/compos/location_map.png";

const LocationPage = () => {
  const {width} = useWindowSize();
  if (typeof window === `undefined`) {
    return(<></>);
  }

  return (
    <main>
      <Seo page={seoData.location} />
      <IntroVideo page={introData.location}/>
      <div className="pb-s"/>
      <section>
        <ContentText
          bgClassName={"bg-t1"}
          heading={textData.location1.heading}
          text={textData.location1.text}
        />
        {width < 600 &&  <Composition imgName={"location_compos_1_1_mob.png"} align={"bottom"} />}
        {width < 600 &&  <Composition imgName={"location_compos_1_mob.png"} align={"offTop2"} />}
        {width >= 1024 && <NewComposition imgSrc={leftPalms4} top={"3vh"} left={width >= 1300? "0" : "-5%"} height={'80vh'}/>}
        {width >= 1024 && <NewComposition imgSrc={rightPalms6} bottom={width >= 1300? "10vh" : "10vh"} right={'0'} height={'30vh'}/>}
      </section>
      <ContentImageText
        type={width >= 600 ? "row" : "col end t1"}
        imgName={"location_2.jpg"}
        heading={textData.location2.heading}
        text={textData.location2.text}
      />
      <ContentImageText
        type={"col end"}
        imgName={"location_3.jpg"}
        text={textData.location3.text}
      />
      <section>
        <Image
          imageName={"location_4.jpg"}
          padding={width >= 1885 ? "4% 9.3% 7%" : "4% 85px 7%"}
          paddingMob={"0 5.2% 7.3%"}
          move={false}
        />
        {width >= 1024 && <NewComposition imgSrc={leftLocation1} bottom={"2vw"} left={"0"} height={'45vw'} zIndex={'1'}/>}
        {width >= 1024 && <NewComposition imgSrc={rightLocation1} bottom={"5vw"} right={'0'} height={'30vw'} zIndex={'1'}/>}
        <ContentLocation />
      </section>
      <section>
        <MapWrapper>
          {width >= 1024 && <img src={locationMap} alt="Ritz Pompano Beach"/>}
          {width <= 500 && <Image imageName={"location_1_mob.jpg"}/>}
        </MapWrapper>

        {width >= 1024 && <CompositionMap imgSrc={mapRight1} top={"-8vw"} right={"2%"} height={'15.8vw'} zIndex={'1'}     delay={'0'}     duration={1.2} fromVars={{'opacity': '0', 'y': "20"}} toVars={{'opacity':'1', 'y': "0", "ease": "power1.inOut"}}/> }
        {width >= 1024 && <CompositionMap imgSrc={mapLeft1} top={"-5vw"} left={"0"} height={'20vw'} zIndex={'1'}          delay={'0.25'}  duration={1.2} fromVars={{'opacity': '0', 'y': "20"}} toVars={{'opacity':'1', 'y': "0", "ease": "power1.inOut"}}/> }
        {width >= 1024 && <CompositionMap imgSrc={mapBoat1} top={"15vw"} right={"25vw"} height={'4vw'} zIndex={'1'}       delay={'1'}     duration={2.5} fromVars={{'opacity': '0', 'x': "60"}} toVars={{'opacity':'1', 'x': "0", "ease": "power1.inOut"}}/> }
        {width >= 1024 && <CompositionMap imgSrc={mapLeft2} top={"14vw"} left={"0"} height={'29.5vw'} zIndex={'1'}        delay={'3'}     duration={1.2} fromVars={{'opacity': '0', 'y': "20"}} toVars={{'opacity':'1', 'y': "0", "ease": "power1.inOut"}}/> }
        {width >= 1024 && <CompositionMap imgSrc={mapRight2} top={"4.5vw"} right={"0"} height={'13vw'} zIndex={'1'}       delay={'2.5'}   duration={1.2} fromVars={{'opacity': '0', 'y': "20"}} toVars={{'opacity':'1', 'y': "0", "ease": "power1.inOut"}}/> }
        {width >= 1024 && <CompositionMap imgSrc={mapRight3} top={"15vw"} right={"0"} height={'13vw'} zIndex={'1'}        delay={'2.75'}  duration={2}   fromVars={{'opacity': '0', 'y': "20"}} toVars={{'opacity':'1', 'y': "0", "ease": "power1.inOut"}}/> }
        {width >= 1024 && <CompositionMap imgSrc={mapRight4} top={"24.5vw"} right={"0"} height={'12.2vw'} zIndex={'1'}    delay={'3.25'}  duration={1.2} fromVars={{'opacity': '0', 'y': "20"}} toVars={{'opacity':'1', 'y': "0", "ease": "power1.inOut"}}/> }
        {width >= 1024 && <CompositionMap imgSrc={mapBoat2} top={"29vw"} right={"10vw"} height={'6vw'} zIndex={'1'}       delay={'3.5'}   duration={2.5} fromVars={{'opacity': '0', 'x': "60"}} toVars={{'opacity':'1', 'x': "0", "ease": "power1.inOut"}}/> }
        {width >= 1024 && <CompositionMap imgSrc={mapRight5} top={"35vw"} right={"0"} height={'15vw'} zIndex={'1'}        delay={'4.25'}  duration={2}   fromVars={{'opacity': '0', 'y': "20"}} toVars={{'opacity':'1', 'y': "0", "ease": "power1.inOut"}}/> }
        {width >= 1024 && <CompositionMap imgSrc={mapLeft3} top={"40vw"} left={"0"} height={'36vw'} zIndex={'1'}          delay={'5'}     duration={1.2} fromVars={{'opacity': '0', 'y': "20"}} toVars={{'opacity':'1', 'y': "0", "ease": "power1.inOut"}}/> }
        {width >= 1024 && <CompositionMap imgSrc={mapBoat3} top={"53vw"} right={"33vw"} height={'5.8vw'} zIndex={'1'}     delay={'5.25'}  duration={2.5} fromVars={{'opacity': '0', 'x': "60"}} toVars={{'opacity':'1', 'x': "0", "ease": "power1.inOut"}}/> }
        {width >= 1024 && <CompositionMap imgSrc={mapRight6} top={"52vw"} right={"0"} height={'11.5vw'} zIndex={'1'}      delay={'6'}     duration={2}   fromVars={{'opacity': '0', 'y': "20"}} toVars={{'opacity':'1', 'y': "0", "ease": "power1.inOut"}}/> }
      </section>
      <ContentImageText
        type={"col end"}
        imgName={"location_5.jpg"}
        text={textData.location4.text}
      />
      <Image
        imageName={"location_6.jpg"}
        padding={width >= 1885 ? "4% 9.3% 9.3%" : "4% 85px 9.3%"}
        paddingMob={"0 5.2% 5.2%"}
        move={false}
      />
      <CompositionWrapper>
        <ContentNextPage
          type={"bg-full"}
          nextTitle={navData.team.title}
          nextLink={navData.team.link}
          vertAlign={'center'}
          mobHeight={"40vh"}
        />
        <NewComposition
          shape={"circle"}
          top={"0"}
          right={"93vw"}
          height={"600px"}
          mobBreakpoint={"phoneXL"}
          mobTop={"-10%"}
          mobRight={"90vw"}
          mobHeight={"100%"}
        />
        <NewComposition
          shape={"triangle"}
          top={"0"}
          right={"0"}
          height={"100%"}
          mobBreakpoint={"phoneXL"}
          mobTop={"auto"}
          mobBottom={"0"}
          mobRight={"0"}
          mobHeight={"80%"}
        />
      </CompositionWrapper>
      <Footer />
    </main>
  );
};

export default LocationPage;
