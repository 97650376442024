import styled from "styled-components";
import {mediaMax} from "../../../helpers/MediaQueries";

export const ContentWrap = styled.div``;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 7%;
  .col {
    width: 27%;
    min-width: 400px;
    margin: 0 3.5%;
    h3 {
      padding-bottom: 20px;
    }
  }

  ${mediaMax.tabletLandscape`
    flex-direction: column;
    align-items: center;
    p {
      text-align: center;
      padding: 0 10%;
    }
    .col {
      min-width: 200px;
      width: 100%;
    }
  `}
  ${mediaMax.tablet`
    p {
      text-align: left;
    }
  `}
`;
