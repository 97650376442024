import styled from "styled-components";

interface IStylesProps {
  height?: string;
  left?: string;
  right?: string;
  bottom?: string;
  top?: string;
  zIndex?: string;
}

export const CompositionImage = styled.img<IStylesProps>`
  height: ${props => props.height};
  left: ${props => props.left};
  right: ${props => props.right};
  top: ${props => props.top};
  bottom: ${props => props.bottom};

  position: absolute;
  z-index: ${props => props.zIndex ? props.zIndex : 0};
  opacity: 0;
  pointer-events: none;
`;
export const CompositionDiv = styled.div<IStylesProps>`
  height: ${props => props.height};
  left: ${props => props.left};
  right: ${props => props.right};
  top: ${props => props.top};
  bottom: ${props => props.bottom};
  position: absolute;
  z-index: 0;
  opacity: 0;
  pointer-events: none;

  &.circle {
    width: ${props => props.height};
    background-color: #FD5F1F;
    border-radius: 50%;
  }

  &.triangle {
    width: 6%;
    background-color: #004987;
    clip-path: polygon(100% 0%, 100% 100%, 0% 100%);
  }
`;

export const CompositionWrapper = styled.section`
  overflow: hidden;

  .shapes-bg:before, .shapes-bg:after {
    display: none;
  }
`;
export const MapWrapper = styled.section`
  width: 100%;
  margin: 0 0 9.3%;
  img {
    width: 100%;
    object-fit: cover;
    object-position: center center;
  }
`;